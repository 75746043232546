import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
var __jsx = React.createElement;
import React from "react";
import { FrequentlyAskedQuestionsComponent, HeroComponent, LeaderboardWinnersScreen, PachinkoComponent, PointShopComponent, RafflesComponent, YoutubeFeedComponent } from "components";
import BeAGuestComponent from "components/ScreenComponent/BeAGuestComponent";
import { useRouter } from "next/router";
import TotalGivenAwayComponent from "components/ScreenComponent/TotalGivenAwayComponent";
import FeaturedCreatorsComponent from "components/ScreenComponent/FeaturedCreatorsComponent";
import { Fade } from "react-awesome-reveal";
import dynamic from "next/dynamic";
import Head from "next/head";
var StartEarningRewardsSectionElement = dynamic(function () {
  return import("components/ScreenComponent/StartEarningRewards/StartEarningRewardsSection");
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("components/ScreenComponent/StartEarningRewards/StartEarningRewardsSection")];
    }
  }
});
var Main = function Main(_ref) {
  var highlights = _ref.highlights,
    latest = _ref.latest;
  var router = useRouter();
  if (router.isFallback) {
    return __jsx(React.Fragment, null, "Loading...");
  }
  return __jsx(React.Fragment, null, __jsx(Head, null, __jsx("title", null, "WRewards"), __jsx("meta", {
    name: "google-site-verification",
    content: "QYkqzD1YpIG2hlghvwwa2EAPZRrWBW0vpSRm-rM28gI"
  })), __jsx(HeroComponent, {
    id: "Hero"
  }), __jsx(Fade, {
    triggerOnce: true,
    direction: "left"
  }, __jsx(FeaturedCreatorsComponent, {
    id: "FeaturedCreators"
  })), __jsx(Fade, {
    triggerOnce: true,
    direction: "right"
  }, __jsx(TotalGivenAwayComponent, {
    id: "TotalGivenAway",
    nextScrollHref: "#BeAGuest"
  })), __jsx(Fade, {
    triggerOnce: true,
    direction: "left"
  }, __jsx(BeAGuestComponent, {
    id: "BeAGuest",
    nextScrollHref: "#LeaderboardWinnersScreen"
  })), __jsx(Fade, {
    triggerOnce: true,
    direction: "right"
  }, __jsx(LeaderboardWinnersScreen, {
    id: "LeaderboardWinnersScreen"
  })), __jsx(Fade, {
    triggerOnce: true,
    direction: "left"
  }, __jsx(PointShopComponent, {
    id: "PointShop"
  })), __jsx(RafflesComponent, {
    id: "Raffles"
  }), __jsx(Fade, {
    triggerOnce: true,
    direction: "left"
  }, __jsx(PachinkoComponent, {
    mainOnly: true,
    id: "Pachinko"
  })), __jsx(Fade, {
    triggerOnce: true,
    direction: "right"
  }, __jsx(YoutubeFeedComponent, {
    id: "YoutubeFeed",
    highlights: highlights,
    latest: latest
  })), __jsx(Fade, {
    triggerOnce: true,
    direction: "left"
  }, __jsx(StartEarningRewardsSectionElement, {
    showRelevant: true
  })), __jsx(FrequentlyAskedQuestionsComponent, {
    id: "FrequentlyAskedQuestions"
  }));
};
export var __N_SSG = true;
export default _withSuperJSONPage(Main);

// export const getStaticPaths: GetStaticPaths = async () => {
// 	return {
// 		fallback: true, // false or "blocking"
// 		paths: [
// 			// {
// 			//   params: {
// 			//     name: 'next.js',
// 			//   },
// 			// }, // See the "paths" section below
// 		],
// 	}
// }